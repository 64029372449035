#dw-sidebar.offcanvas
{
  top: $dw-brand-line-height;
  color: $dw-gray-dark;
  overflow-y: auto;
  height: calc(100vh - $dw-brand-line-height);
  box-shadow: 0 6px 18px 0 rgb(0 0 0 / 18%);

  @include media-breakpoint-down("lg") {
    width: 240px;
  }

  // Firefox Support
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  // Chrome + Webkit Support

  &::-webkit-scrollbar {
    width: 10px;
  }

  @include media-breakpoint-up("xxl") {

    &::-webkit-scrollbar-track {
      background-color: lighten($dw-color, 10%);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dw-color;
      border-radius: 50rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: darken($dw-color, 10%);
    }

    scrollbar-color: $dw-color lighten($dw-color, 10%);

    overflow-y: hidden;

    &:hover, &:focus, &:active {
      overflow-y: auto;
    }
  }

  > ul {
    margin: 0;
    padding: 0;

    > li {
      list-style: none;

      &.divider {
        text-transform: uppercase;
        font-size: $small-font-size;
        padding: 1.5rem 0 0 $spacer;
        line-height: $line-height-lg;
      }

      a {
        color: $dw-gray-light;
        display: block;
        padding: 0 $spacer;
        line-height: $line-height-lg;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:hover {
          background-color: $gray-200;
        }
      }

      &.parent {

        > a::before {
          float: right;
          font-family: $dw-icons-font;
          font-weight: $dw-fas-font-weight;
          content: "\f077";
        }

        > a.collapsed::before {
          content: "\f078" !important;
        }

        ul.sub-menu {
          margin: 0;
          padding: 0;
          background-color: $gray-100;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          font-size: $small-font-size;
          line-height: $line-height-base;

          > li {
            list-style: none;

            > a {
              padding-left: calc($spacer + 1.75rem);

              &.dw-folder {

                font-size: $font-size-base;
                line-height: $line-height-lg;

                &::before {
                  width: 1.25rem;
                  margin-right: .5rem;
                  font-size: $font-size-base;
                  font-family: $dw-icons-font;
                  font-weight: $dw-fas-font-weight;
                  color: $dw-color;
                  content: "\f07c";
                }

                &.collapsed::before {
                  content: "\f07b" !important;
                }

              }
            }
          }
        }

      }
    }
  }

}

// Always show sidebar at higher resolutions

@include media-breakpoint-up("xxl") {

  #dw-sidebar.offcanvas {
    transition: none !important;
    visibility: hidden !important;
  }

  body.dw-show-sidebar {

    #dw-sidebar.offcanvas {
      box-shadow: none !important;

      transform: none !important;
      visibility: visible !important;
      background-color: lighten($dw-color, 10%) !important;
      color: $dw-color-invert !important;

      > ul > li {

        a {
          color: $dw-color-invert !important;

          &:hover {
            background-color: transparent !important;
            color: rgba(255,255,255,.75) !important;
            @include transition($nav-link-transition);
          }

        }

        ul.sub-menu {
          background-color: transparent !important;

          > li > a.dw-folder {
            &::before {
              color: $dw-color-invert !important;
            }

            &:hover::before {
              color: rgba(255,255,255,.75) !important;
            }
          }
        }
      }
    }

    > .be-wrapper {

      > .page-aside, .be-content {
        margin-left: $offcanvas-horizontal-width;
      }

      .be-sub-navigation.fixed-top {
        left: $offcanvas-horizontal-width;
      }

      @include media-breakpoint-up("md") {
        &.be-aside .main-content {
          margin-left: calc($offcanvas-horizontal-width + 300px);
        }
      }
    }
  }
}
