ul.timeline {
  padding: 1rem;
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-down("md") {
    padding: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 1.5rem;
    bottom: 0;
    width: 2px;
    background: $border-color;
    left: 13.5rem;
    margin-left: -1px;

    @include media-breakpoint-down("md") {
      left: 50%;
      margin-left: -1px;
      top: -2rem;
    }
  }

  li.timeline-item {
    padding-left: 14rem;
    list-style: none;
    margin-bottom: 2rem;
    position: relative;

    @include media-breakpoint-down("md") {
      margin-bottom: 0;
      margin-top: 5rem;
      padding-left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 1.5rem;
      left: 12rem;
      border: 2px solid $dw-color;
      width: 16px;
      height: 16px;
      background-color: $body-bg;
      border-radius: 50px;
    }

    .timeline-date {
      position: absolute;
      display: block;
      left: 0;
      top: 1.5rem;
      width: 11rem;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include media-breakpoint-down("md") {
        text-align: center;
        top: -4rem;
        width: 100%;

        span {
          background-color: $dw-gray-bg;
          border-radius: $border-radius;
          line-height: $line-height-lg;
          padding: .5rem 1rem;
          display: inline-block;
        }
      }
    }

    .timeline-content {
      position: relative;
      background-color: $white;
      border: 1px solid $border-color;
      padding: 1rem;
      border-radius: $border-radius;
      box-shadow: $box-shadow-sm;
      word-break: break-word;
      font-size: $font-size-sm;

      @include media-breakpoint-down("md") {
        font-size: $font-size-base;
      }

      &:before {
        position: absolute;
        background-color: $white;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        top: 1.5rem;
        left: -5px;
        content: "";

        @include media-breakpoint-down("md") {
          display: none;
        }
      }

      &:after {
        position: absolute;
        background-color: transparent;
        transform: rotate(45deg);
        box-shadow: $box-shadow-sm;
        z-index: -1;
      }

      .timeline-avatar {
        width: 30px;
        height: 30px;
        display: block;
        overflow: hidden;
        vertical-align: middle;
        float: left;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .timeline-header {
        margin-left: calc(35px + .5rem);

        .timeline-author {
          font-weight: bold;
          font-size: $font-size-base;
        }

        .timeline-activity {
          display: inline-block;
          margin: 0;
        }

        .timeline-summary {
          color: $dw-gray-light;
          margin-top: .25rem;
          line-height: $line-height-sm;
        }
      }


    }
  }

}
