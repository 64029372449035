body.modal-open {
  padding-right: 0 !important;

  .navbar.fixed-top {
    padding-right: $navbar-padding-x !important;

    @include media-breakpoint-down("lg") {
      padding-right: 1rem !important;
    }
  }
}

a.dropdown-toggle {
  outline: none;
}

.tooltip,
.btn:disabled {
  pointer-events: none;
}

.btn, .badge.bg-black, .badge.bg-white {
  border-radius: 50rem;
}

.btn-secondary {
  border-width: 1px;
  border-style: solid;
  @include button-variant($white, $gray-400, $black, $white, $gray-600);
}

@include media-breakpoint-up("md") {
  .btn-sm.btn-md-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
  }
}
