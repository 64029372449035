
/*-----
Breadcrumbs
-----*/

ol.breadcrumb {

  li.breadcrumb-item a {
    color: $dw-gray-light !important;
  }
}

.breadcrumb-item + .breadcrumb-item {

	&::before {
		// content: '\f105';
		font-family: $dw-icons-font;
		font-weight: $dw-fas-font-weight;
	}
}

@include media-breakpoint-down("md") {

  li.breadcrumb-item + .breadcrumb-item {

    &::before {
      content: none !important;
    }

    a > span::before {
      font-family: $dw-icons-font;
      font-weight: $dw-fas-font-weight;
      content: '\f100' !important;

      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: $breadcrumb-item-padding-x;
      color: $breadcrumb-divider-color;
      text-decoration: none;
    }

    padding-left: 0 !important;
  }
}
