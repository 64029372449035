// DH

.dw-editorial {
    .dw-post-body {
        p {
            font-size: 1.2rem;
            line-height: 1.5;
        }
    }

    .dw-forum-post {
        border: none !important;
        box-shadow: none !important;
        margin-bottom: 0;

        .card-body {
            padding: 0 !important;
        }

        .card-footer {
        	padding-left: 0 !important;
        	padding-right: 0 !important;
    	}
    }

    .btn-group.dw-card-hidden {
        .dw-card-hidden-item {
            opacity: .5;
            color: $dw-gray-light;
        }
    }

    .dw-comments {
        margin-top: 3rem;
        margin-bottom: 0;

        .card {
            top: unset;
            margin-bottom: 0;
            margin-top: 15px;

            .card-body {
                border: $dw-card-border;
            }
        }

    }
}
