.nav-tabs {

  .nav-link {
    border: none;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border: none;
    border-bottom: solid $primary;
  }
}
