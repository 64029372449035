// DH

// Start CM 6

#js-insert-code {
  z-index: 1056;
}

.js-codemirror {

  // if CM exists, hide textarea
  textarea:not(:last-child) {
    display: none;
  }

  textarea, .cm-editor {
    border: $dw-card-border !important;
    box-shadow: $dw-card-box-shadow !important;
    border-radius: $border-radius-sm;
    height: $dw-codemirror-height;
    outline: 0 !important;
    resize: none;

    // Insert Code

    #js-insert-code  & {
      height: 70vh;
      padding: 0;
    }

    // Editorial

    #dw-newsletter-editor &,
    .step-content &,
    .modal-fullscreen & {
      height: auto !important;
      min-height: $dw-codemirror-height;
      max-height: $dw-codemirror-height-lg;
    }

    .dw-editor-sm & {
      height: $dw-codemirror-height-sm !important;
    }
  }

  textarea {
    padding: $dw-codemirror-padding; // 1rem
  }

  .cm-scroller {
    padding: .75rem;
  }

  /*

  .cm6-inlinecode,
  .cm-line:empty,
  .cm-line:has(.cm6-code),
  .cm-line:has(.cm6-code) + .cm-line {
    font-family: $font-family-monospace;
  }
  */

  textarea, .cm-scroller {
    /* font-family: $font-family-sans-serif !important; */
    font-family: $font-family-monospace !important;
    font-size: $font-size-base !important;
    line-height: $line-height-base !important;

    #js-insert-code-snippet &,
    #js-insert-code & {
      font-family: $font-family-monospace !important;
      padding: 0;
    }
  }
}

// End CM 6

// Editor Preview

.js-show-preview .card-body {
	max-height: $dw-codemirror-height-lg;
	overflow-y: auto;
}
