.dropzone {

  border: 1px dashed $border-color !important;
  background: $white;

  &:empty {
    display: none;
  }

  .dz-preview.dz-file-preview .dz-image {
    background: $dw-gray-bg !important;
  }

}

