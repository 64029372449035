.tt-menu {
  background: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  max-height: 40rem;
  overflow-y: auto;
  width: 100%;
}

.tt-dataset
{
  display: flex;
  flex-wrap: wrap;
  margin: .5rem;

  &:first-child {
    margin-top: 0;
  }

  .tt-suggestion {
    display: block;
    padding: .25rem;
    white-space: nowrap;
    overflow: hidden;
    color: $dw-gray-dark;

    &:hover {
      color: darken($primary, 10%);
    }
  }

  > strong {
    display: block;
    width: 100%;
    padding: 1rem 0 .5rem 0;
    color: $dw-color;
  }

  &.tt-dataset-search-other .tt-suggestion,
  &.tt-dataset-search-usernames .tt-suggestion,
  &.tt-dataset-search-articles .tt-suggestion {
    display: block;
    width: 100%;
  }

  &.tt-dataset-search-members .tt-suggestion {
    display: inline-block;
    padding-right: 1rem;
  }

  &.tt-dataset-search-tags .tt-suggestion {
    display: inline-block;

    &:hover .badge {
      background-color: $dw-btn-link-bg-hover;
    }
  }
}
