@include media-breakpoint-down("lg") {
  :root {
    --bs-root-font-size: 14px !important;
  }
}

html {
  height: 100%;
  overflow-x: hidden;
  font-size: $font-size-base;
}

body {
  padding: 0;
  height: auto;
  min-height: 100%;
}

code {
  color: $success;
}

pre {
  background-color: $dw-pre-bg;
  border: 1px solid $border-color;
  border-radius: $border-radius-sm;
  padding: $dw-pre-padding;
}

@include media-breakpoint-up("lg")
{
  time {
    text-decoration: underline;
    text-decoration-color: green;
    text-decoration-style: dotted;
    text-underline-position: under;
    cursor: help;

    a & {
      cursor: pointer !important;
    }
  }
}

:target::before{
  content: '';
  display: block;
  height: 60px;
  margin-top: -60px;
  visibility: hidden;
}
