// DH

/*-----
Avatar Badge for Currently Online
-----*/

.dw-online-container {
	width: 90px;
	height: 90px;
}

.dw-author-container {
  width: 60px;
  height: 60px;
  min-width: 60px;
}

.dw-has-online {

	position: relative;

	// Height of avatar
	height: 60px;

	// Avatar dimensions
	img {
		width: 60px;
		height: 60px;
	}

	.dw-online {
		position: absolute;
		bottom: -30px;
		text-align: center;
		z-index: 1;
		display: block;
		width: 100%;
		left: 0;
		right: 0;
		margin: auto;

		i.fas {
			color: $dw-color;
		}
	}

	// Sidebar / Conversations
	&.dw-has-online-sm {

		// Height of avatar
		height: 45px;

		// Avatar dimensions
		img {
			width: 45px;
			height: 45px;
		}

		.dw-online {
			bottom: calc(#{45px} / -2);
		}
	}
}
