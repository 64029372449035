.card-table {
  .card-body {
    padding: 0;
  }

  table {
    margin-bottom: 0;

    tr {

      &:last-child td {
        border-bottom: none;
      }

      &.border-start {

        border-left-width: 6px !important;
        border-left-style: solid;

        td {
          border-color: $table-border-color;
        }
      }

      td:first-child {
        padding-left: $card-spacer-x;
      }

      td:last-child {
        padding-right: $card-spacer-x;
      }
    }

    &.table-hover {

      tbody:first-child {
        tr:first-child {
          td:first-child {
            border-top-left-radius: $card-border-radius;
          }

          td:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: $card-border-radius;
        }

        td:last-child {
          border-bottom-right-radius: $card-border-radius;
        }
      }
    }


  }

}
