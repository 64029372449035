// DH

// Featured Topics

.dw-featured {
	background-color: $dw-color-featured;

  &:first-child {

    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;

    td:first-child {
      border-top-left-radius: $card-border-radius;
    }

    td:last-child {
      border-top-right-radius: $card-border-radius;
    }
  }

  &:last-child {

    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;

    td:first-child {
      border-bottom-left-radius: $card-border-radius;
    }

    td:last-child {
      border-bottom-right-radius: $card-border-radius;
    }
  }

}

// Vote + Unvote on Topics

.dw-undo {
	background-color: $dw-gray-bg !important;
	border-color: $border-color !important;
	color: $dw-gray-dark !important;
}

.dw-upvote .badge,
.dw-downvote .badge {
	background-color: $gray-100;
}

// Comment on Topic with Reputation

#md-comment .btn .badge {
	background-color: inherit;
	color: inherit;
	margin-left: .75rem;
}
