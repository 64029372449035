#js-toast-container {
  position: fixed;
  right: .5rem;
  // top: calc($dw-brand-line-height + .5rem);
  bottom: .5rem;
  z-index: 1020;

  .toast {
    cursor: pointer;
  }
}
