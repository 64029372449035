
/*-----
Quotes
-----*/

blockquote,
.blockquote {
	background-color: $dw-blockquote-bg;
	font-size: inherit;
	font-style: italic;
	padding: $dw-blockquote-padding;
	position: relative;

	blockquote,
	.blockquote {
		background-color: $white;
	}
}

.blockquote:after,
blockquote:after {
	bottom: -.5rem;
	content: "\f10e";
	font-family: $dw-icons-font;
	font-weight: $dw-fas-font-weight;
  font-size: $dw-blockquote-padding;
	position: absolute;
	right: 0;
}

.blockquote:before,
blockquote:before {
	content: "\f10d";
	font-family: $dw-icons-font;
  font-weight: $dw-fas-font-weight;
  font-size: $dw-blockquote-padding;
	left: 0;
	position: absolute;
	top: -.5rem;
}
