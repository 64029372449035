// DH

// Forum Topics
@include media-breakpoint-up("lg") {
	.dw-col-container {
		max-width: calc(100% - #{$dw-col-sidebar-width}) !important;
	}
	.dw-col-sidebar {
		max-width: $dw-col-sidebar-width !important;
	}
}

// Forums + Tags
@include media-breakpoint-up("xl") {
	.dw-col-xl-container {
		max-width: calc(100% - #{$dw-col-xl-sidebar-width}) !important;
	}
	.dw-col-xl-sidebar {
		max-width: $dw-col-xl-sidebar-width !important;
	}
	.dw-col-xl-container.order-xl-1 {
		max-width: inherit !important;
	}
	.dw-col-xl-sidebar.order-xl-0 {
		width: $dw-col-xl-sidebar-width !important;
	}
}
