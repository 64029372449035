.wizard {

  border-radius: $card-border-radius;
  background-color: $white;

  .steps-container > .steps {
    list-style: none;
    padding: 0;
    margin: 0;


    a, li {
      float: left;
      margin: 0;
      position: relative;
      padding-right: $spacer;
      padding-left: $spacer * 2;
      background-color: $primary;
      color: $white;
      height: 48px;
      line-height: 48px;

      &:first-of-type {
        border-top-left-radius: $card-border-radius;
      }

      .chevron {
        border: 24px solid transparent;
        border-left: 14px solid $primary;
        border-right: 0;
        display: block;
        position: absolute;
        right: -14px;
        top: 0;
        z-index: 1;
      }


      &.active {
        background-color: $info;
        color: $white;

        .chevron {
          border-left-color: $info;
        }
      }

    }
  }
}
