// DH

.text-dw {
	color: $dw-color !important;
}

// Homepage monospaced font
.dw-heading {
	font-family: $font-family-monospace !important;
	color: $dw-color;
}

// Reply slide-up header + Modal headers
.dw-header,
.colored-header-primary .modal-content .modal-header {
	background-color: $dw-color !important;
	color: $dw-color-invert !important;
}

// Reply slide-up header
#js-quick-reply
{
	&.fixed-bottom.sticky-top {
		top: initial !important;
	}

	.collapsed .dw-collapsed-display .far:before {
		content: "\f2d2";
	}
}
