// DH

#js-conversations
{
  background-color: $gray-100;
  top: $dw-brand-line-height;
  height: calc(100vh - $dw-brand-line-height);
  overflow-x: hidden;
  width: 82px;

  // Firefox Support
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  scrollbar-color: $gray-400 $gray-100;

  // Chrome + Webkit Support
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $gray-100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: 50rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray-600;
  }

  overflow-y: hidden;

  &:hover, &:focus, &:active {
    overflow-y: auto;
  }

  @include media-breakpoint-up("md") {
    width: 300px;
  }

  ul {

    padding: 0;
    margin: 1rem 0 0 0;

    > li
    {
      list-style: none;

      &.active a {
        background-color: $dw-gray-bg;
      }

      a {
        display: block;
        padding: .5rem;
        margin: 0 .5rem;
        color: $dw-gray-dark;
        overflow: visible;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: $border-radius;

        @include media-breakpoint-up("md") {
          padding: 1rem .5rem 0 .5rem;
          margin: -1px .5rem 0 .5rem;

          &:hover {
            background-color: $dw-gray-bg;
          }
        }

      }
    }

  }

}

