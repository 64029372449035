@include media-breakpoint-up("md") {
  .be-aside {
    .main-content {
      margin-left: 300px;
    }
  }
}

.page-aside {
  width: 300px;
  padding: 2rem 1.5rem;
  height: calc(100vh - $dw-brand-line-height);
  position: fixed;
  background: $white;
  border-right: 1px solid $border-color;
  font-size: $font-size-sm;

  .be-sub-navigation + & {
    height: calc(100vh - $dw-brand-line-height * 2);
  }

  // Firefox Support
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  scrollbar-color: $gray-400 $white;

  // Chrome + Webkit Support
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-400;
    border-radius: 50rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray-600;
  }

  overflow-y: hidden;

  &:hover, &:focus, &:active {
    overflow-y: scroll;
  }
}
