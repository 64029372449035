// DH

#md-signup .dw-modal-hide {
	display: none;
}

// Tags

#js-suggested-tags {
	display: none;
	z-index: 10;
}

#md-article-tags #js-suggested-tags {
	top: 2rem;
}

#md-article-tags .modal-content {
	overflow: visible;
}

.js-added-tags {
	width: 1%;
}
