// DH

// Homepage activity stream
#masonry-grid {

	// Fixed ad size populated within grid
	.dw-mpu {
		height: 250px;
	}

  .dw-activity-title {
    color: $text-muted;
    font-size: $small-font-size;
    text-transform: uppercase;
    text-align: center;
  }

	.card:hover {
		.dw-activity-title {
			background-color: $dw-color;
			color: $dw-color-invert !important;
		}
	}
}

img.dw-cover-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 10rem;

  &.dw-cover-img-top {
    object-position: top;
  }
}
