// DH

/*-----
// Open Sans Google Font
-----*/

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: fallback;
	src: url(https://cdn.daniweb.com/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: fallback;
	src: url(https://cdn.daniweb.com/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: fallback;
	src: url(https://cdn.daniweb.com/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2) format('woff2');
}
