.dw-row-hidden .dw-row-hidden-item,
.dw-card-hidden .dw-card-hidden-item {

  @include media-breakpoint-up("lg") {
    opacity: 0;
  }

  color: $dw-gray-light;
}

.dw-faded {
	opacity: .25;
}

.dw-deleted
{
  opacity: .5;
}

@include media-breakpoint-up("lg") {
  .card:hover .dw-card-hidden .dw-card-hidden-item,
  .dw-card .dw-card-hidden-item,
  .dw-row-hidden:hover .dw-row-hidden-item {
    opacity: .5;
  }
}

.dw-deleted:hover,
.dw-card-hidden:hover .dw-card-hidden-item,
.dw-card:hover .dw-card-hidden-item,
.dw-row-hidden .dw-row-hidden-item:hover {
	opacity: 1 !important;
}

.dw-d-none {
	display: none;
}

.dw-no-box-shadow {
	background-color: transparent;
	border: none;
	box-shadow: none;
	outline: none;
}
