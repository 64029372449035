.alert-icon {
  background-color: $white;
  width: 100%;
  display: table;
  table-layout: fixed;
  position: relative;
  margin-bottom: $alert-margin-bottom;
  // border: $alert-border-width solid $border-color;

  @include border-radius($alert-border-radius);

  > .icon {
    font-size: $h2-font-size;
    color: $white;
    width: 5rem;
    position: relative;
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    :after {
      right: -11px;
      border-left: 11px solid $white;
      border-bottom: 11px solid transparent;
      border-top: 11px solid transparent;
      position: absolute;
      margin-top: -10px;
      top: 50%;
      width: 0;
      height: 0;
      z-index: 2;
      content: "";
    }
  }

  > .message {
    display: table-cell;
    padding: $alert-padding-y $alert-padding-x;
    vertical-align: middle;

    p {
      margin: 0;
    }
  }

  @each $state, $value in $theme-colors {

    $alert-background: $value;
    $alert-border: shift-color($value, $alert-border-scale);

    &.alert-#{$state} {

      border: $alert-border-width solid $alert-border;

      > .message {
        background-color: shift-color($value, $alert-bg-scale);
      }

      > .icon {

        background-color: $alert-background;

        :after {
          border-left-color: $alert-background;
        }
      }

    }

  }
}
