.card-title {
  font-size: $font-size-lg;
}

.card-body {
	overflow-x: hidden;
	overflow-y: auto;

	&.table-responsive {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.card-columns {
	column-count: 1 !important;
  page-break-inside: avoid;
  break-inside: avoid;
  orphans: 1;
  widows: 1;
  column-gap: $grid-gutter-width;
}

@include media-breakpoint-up("md") {
	.card-columns {
		column-count: 2 !important;
	}
}

@include media-breakpoint-up("lg") {
	.card-columns {
		column-count: 3 !important;
	}
}

.tab-content {

	.card {

		.card-body {
			padding-right: $card-spacer-x;
			padding-left: $card-spacer-x;
		}
	}
}
