#dw-member-profile
{
  .card.card-border
  {
    .card-header:last-child
    {
      border-bottom: none;
    }
  }

	// Break apart containers into individual cards

	@include media-breakpoint-down("lg") {

    .card.dw-card-shadow {
      border: none !important;
      box-shadow: none !important;
      background: none !important;
      border-radius: 0 !important;
      margin-bottom: 0;

      .card-body {
        overflow: visible;
      }
    }

		.dw-profile-body {
			padding: 0;

      #chartdiv {
        margin-bottom: 1rem;
        padding: $dw-card-padding;
        background: white;
      }

      .card.card-border,
      #chartdiv {
        border: $dw-card-border;
        border-radius: $dw-card-radius;
        box-shadow: $dw-card-box-shadow;
        margin-bottom: 2rem !important;
      }

      .row {
        margin: 0 !important;
      }

      .col,
      .col-lg-6,
      .col-xl-6 {
        padding: 0 !important;
      }

		}

	}
}
