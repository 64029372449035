.parsley-errors-list.filled {
  list-style: none;
  background: $danger;
  border-radius: $border-radius;
  color: $white;
  padding: .75rem 1rem;
  margin-top: 14px;
  position: relative;

  &:after {
    display: inline-block;
    position: absolute;
    top: -6px;
    left: 1rem;
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-bottom: 7px solid $danger;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
}
