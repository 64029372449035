// DH

#dw-search-container {

  #dw-site-search {
    background-color: transparent;
    border: 1px solid $border-color;
    color: $dw-color-invert;
    font-size: 1.2rem;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;

    &::placeholder {
      color: $border-color;
      opacity: 1;
    }
  }

  .twitter-typeahead {
    display: flex !important;
  }
}

/*-----
Typeahead.js Search Suggestions
-----*/

#dw-search-container {
  .tt-hint {
    background-color: transparent !important;
    color: $dw-color-invert !important;
  }
}
