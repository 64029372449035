// DH

// Avatars sit outside .dw-forum-post in some places

.dw-post,
.dw-forum-post {

	// Avatar thumbnail
	.dw-online-container {

    img.rounded-circle {
      border-color: transparent !important;
      height: 60px;
      width: 60px;
      margin: $card-cap-padding-x;
      position: relative;
      z-index: 1;
    }
	}
}

.dw-card-shadow,
.dw-forum-post {

	/*-----
	Look of a forum post card
	-----*/

	border: $dw-card-border;
	border-radius: $dw-card-radius;
	box-shadow: $dw-card-box-shadow;
  margin-bottom: 2rem;

	/*-----
	Unread Post
	-----*/

	&.dw-border-primary {
		border-left: $dw-border-width solid $primary !important;
	}

  &.dw-border-success {
    border-left: $dw-border-width solid $success !important;
  }

  &.dw-border-info {
    border-left: $dw-border-width solid $info !important;
  }

  &.dw-border-warning {
    border-left: $dw-border-width solid $warning !important;
  }

  &.dw-border-danger {
    border-left: $dw-border-width solid $danger !important;
  }

}

.dw-forum-post {

  display: block;

  header {
    padding-left: calc(#{$card-cap-padding-x} * 2 + 60px);
  }

  div.card-header {
    margin-left: $card-cap-padding-x;
    margin-right: $card-cap-padding-x;
    padding-left: 0;
    padding-right: 0;
    background: none !important;
  }
}

@include media-breakpoint-up("md") {

	// In smaller resolutions, don't separate avatars out from the post body

	.dw-replies {

    .dw-forum-post {

      header {
        padding-left: $card-cap-padding-x;

        .card-title {
          float: left;
        }
        time {
          float: right;
        }
      }
    }

		.dw-forum-post,
		.dw-inject-bsa {
			margin-left: calc(#{$card-cap-padding-x} * 2 + 60px);
		}

		.dw-online-container {
      position: relative !important;
			width: 60px;
			height: 60px;

      img.rounded-circle {
        margin: 0 !important;
      }
		}

		.dw-featured-answer {
			position: absolute;
      display: inline-block;
      text-align: center;
			left: calc((#{$card-cap-padding-x} * 2 + 60px) * -1);
      width: 60px;
			top: 80px;
			background: $body-bg;
			padding: 5px;
		}
	}

}

// Post body

.dw-post-body {

	a {
		// Icon after links
		&:after {
			color: $gray-600;
			content: '\f35d';
			font-family: $dw-icons-font;
			font-weight: $dw-fas-font-weight;
			margin: 0 .25rem;
		}

    &[href^="/"]:after {
      content: '\f0c1';
    }

		&:hover {
			text-decoration: underline;
		}

		// Broken links within posts
		&.dw-bad-link,
		&.dw-bad-link:hover {
			text-decoration: line-through !important;
		}
	}

	// Inline images

	img {
		background-color: $dw-gray-bg;
		border: 1px solid $border-color;
		border-radius: $border-radius;
		height: auto;
		max-width: 100%;
		padding: 0.25rem;
		display: block;
		margin: 1rem auto;

		// Floated Left
		&[title="align-left"] {
			float: left;
			margin: 1rem;
		}

		// Floated right
		&[title="align-right"] {
			float: right;
			margin: 1rem;
		}
	}

	:first-child
	{
		margin-top: 0;
	}
}

/*-----
Link Thumbshot
Added to bottom of page but generated inside post body
-----*/

.popover.dw-popover-thumbnail img {
	width: 100%;
}

@include media-breakpoint-up("md") {
	.popover.dw-popover-thumbnail {
		width: 400px;
		max-width: 400px;
	}
}

/*-----
Synopsis of a post
-----*/

.dw-post-brief {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
