
/*-----
Navigation Menu
-----*/

.navbar.navbar-dark {
  background-color: $dw-color;
	height: $dw-brand-line-height;

  @include media-breakpoint-down("lg") {
    padding: $navbar-padding-y 1rem;
  }

  .fa-bars {
    font-size: 2rem;

    @include media-breakpoint-down("lg") {
      font-size: 1.5rem;
    }
  }
}

.navbar-brand {
  display: inline-block;
	background-image: $dw-brand;
	background-size: $dw-brand-width $dw-brand-height;
	width: $dw-brand-width;
	height: $dw-brand-height;
	line-height: $dw-brand-line-height;
	padding: 0;
	margin: 0;

  @include media-breakpoint-down("lg") {
    background-size: $dw-brand-width-sm $dw-brand-height-sm !important;
    width: $dw-brand-width-sm !important;
    height: $dw-brand-height-sm !important;
  }
}

@include media-breakpoint-up("lg") {
	.sticky-top {
		top: calc($dw-brand-line-height + 2rem);
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;

		.dw-sticky-nav &
		{
      top: calc($dw-brand-line-height * 2 + 2rem);
		}
	}

	.dw-sticky-nav .be-sub-navigation
	{
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: $dw-brand-line-height;
		z-index: 1020;

    .modal-open &
    {
      top: 0;
    }
	}
}

@include media-breakpoint-down("lg") {
	.sticky-top {
		position: static !important;
	}
}

.be-toggle-left-sidebar {
	display: none;
}

.be-offcanvas-menu .be-toggle-left-sidebar {
	display: inline-block;
}
