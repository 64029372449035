// DH

.btn {
  word-break: break-all;
}

.btn-space {
  margin-left: .5rem;
  margin-right: .5rem;
}

.btn-dw {
  @include button-variant($dw-color, $dw-color-light, $dw-color-invert);
}

.btn-success,
.btn-danger
{
  color: $dw-color-invert !important;
}

.btn-dw-link {
  border: 1px solid $dw-btn-link-border;
	background-color: $dw-btn-link-bg;
	color: $dw-btn-link-color;
	font-weight: $font-weight-normal !important;
  text-decoration: none;

	&:hover {
    color: $dw-btn-link-color;
		background-color: $dw-btn-link-bg-hover;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
    color: $dw-btn-link-color;
		background-color: $dw-btn-link-bg-active;
	}
}

#js-add-tags.disabled {
  background-color: $gray-100;
}

.btn-dw-link.disabled {
  cursor: default;
  color: lighten($dw-btn-link-color, 10%);
  background-color: lighten($dw-btn-link-bg-hover, 10%);
}

.btn-dw-link.btn-dw-selected {
	background-color: $dw-btn-link-bg-hover;
}

.btn-transparent {
	background-color: transparent !important;
	color: $white !important;
}

.dw-btn-close {
	background-color: transparent;
	border: 0;
	padding: 0;
}

.btn-rss {
  @include button-variant(
    $background: #f26522,
    $border: #f26522,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.btn-facebook {
  @include button-variant(
    $background: #5d82d1,
    $border: #5d82d1,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.btn-twitter {
  @include button-variant(
    $background: #50bff5,
    $border: #50bff5,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.btn-linkedin {
  @include button-variant(
    $background: #0a66c2,
    $border: #0a66c2,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.btn-mastodon {
  @include button-variant(
    $background: #563acc,
    $border: #0a66c2,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.btn-reddit {
  @include button-variant(
    $background: #FF4500,
    $border: #0a66c2,
    $color: $white,
    $hover-color: $white,
    $active-color: $white
  );
}

.dw-btn-code {
  position: absolute;
  z-index: 1;
  top: .5rem;
  right: .5rem;

  &:active {
    i.far {
      font-weight: 900;
    }
  }
}
