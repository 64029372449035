// DH

.fas, .far, .fab {

	text-align: center;
	
	&.dw-btn-icon {
		width: 1.25em;	
	}
	
	&.dw-btn-icon-lg {
		width: 1.5em;
	}
			
	&.fa-fw {
		margin-right: .5rem!important;
	}
	
	&.fa-large {
		font-size: 1.5rem!important;
		margin-right: 1rem!important;
	}	
}