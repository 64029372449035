body.be-wrapper-container {
  padding-top: $dw-brand-line-height;

  .be-wrapper {
    padding-top: 0;
  }
}

.be-wrapper {
  padding-top: $dw-brand-line-height;
  min-height: 100%;

  .be-content {
    background-color: $body-bg;
    position: relative;
    left: 0;

    .be-sub-navigation {
      min-height: $dw-brand-line-height;
      background-color: $white;
      border-bottom: 1px solid $border-color;
      box-shadow: $dw-card-box-shadow;
      padding: .65rem $navbar-padding-x;
      z-index: 5;

      @include media-breakpoint-down("lg") {
        padding: .65rem 1rem !important;
      }

      &.fixed-top {
        top: $dw-brand-line-height;
      }
    }

    .main-content {
      padding: 2rem $navbar-padding-x;

      @include media-breakpoint-down("lg") {
        padding: 1rem !important;
      }
    }
  }
}

body.dw-fixed-page
{
  padding: $dw-brand-line-height 0 0 0;
  overflow: hidden;
  height: auto;
  min-height: 100%;

  .be-sub-navigation + div {

    position: fixed;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 100%;

    table tr.dw-fixed-headings th {
      position: sticky;
      top: 0;
    }

    height: calc(100vh - $dw-brand-line-height * 2);

    @include media-breakpoint-up("md") {
      height: calc(100vh - $dw-brand-line-height * 2 - 90px - 2rem);
    }

    @include media-breakpoint-up("lg") {
      height: calc(100vh - $dw-brand-line-height * 2 - 250px - 2rem);
    }
  }
}
