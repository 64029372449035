#dw-splash {
  .min-vh-100 {
    @include media-breakpoint-down("md") {
      min-height: 0 !important;
      background-color: $white !important;
      color: $dw-color !important;

      img {
        content: url("https://static.daniweb.com/small-logo.png");
        width: 201px;
        height: 22px;
      }
    }
  }
}
