// DH

.dw-comments {
  margin-top: .5rem;
	margin-bottom: 1rem;
	position: relative;
  top: -1rem;

	.card {
		margin-bottom: .5rem;

    .card-body {
      padding: .75rem 1rem;
    }
	}

	&.hidden:before {
		display: none;
	}

	@include media-breakpoint-up("md") {
		// .dw-comments within .dw-post
		.dw-post:not(.dw-editorial) & {
			margin-left: 3rem;

			&:before {
				top: calc((1rem - 3px) * -1) !important;
			}
		}

    .dw-replies .dw-post & {
      margin-left: calc($card-cap-padding-x * 2 + 60px + 3rem);
    }

	}
}

@include media-breakpoint-up("md") {
	.dw-post:not(.dw-editorial) .dw-comments:before,
	.dw-replies:before {
		background: $gray-400;
		bottom: 0;
		content: '';
		left: 30px;
		margin-left: -1px;
		position: absolute;
		top: 0;
		width: 2px;
	}
}
